import AvenirTTF from "../fonts/Avenir/Avenir-Book-01.ttf";
import AvenirWOFF from "../fonts/Avenir/Avenir-Book-01.woff";
import AvenirMediumTTF from "../fonts/Avenir/Avenir-Medium-09.ttf";
import AvenirMediumWOFF from "../fonts/Avenir/Avenir-Medium-09.woff";
import AvenirLightTTF from "../fonts/Avenir/Avenir-Light-07.ttf";
import AvenirLightWOFF from "../fonts/Avenir/Avenir-Light-07.woff";
import AvenirHeavyTTF from "../fonts/Avenir/Avenir-Heavy-05.ttf";
import AvenirHeavyWOFF from "../fonts/Avenir/Avenir-Heavy-05.woff";

import AvenirNextOTF from "../fonts/avenir-next/avenir-next-regular.otf";
import AvenirNextWOFF from "../fonts/avenir-next/avenir-next-regular.woff";
import AvenirNextSemiBoldOTF from "../fonts/avenir-next/avenir-next-semi-bold.otf";
import AvenirNextSemiBoldWOFF from "../fonts/avenir-next/avenir-next-semi-bold.woff";
import AvenirNextBoldOTF from "../fonts/avenir-next/avenir-next-bold.otf";
import AvenirNextBoldWOFF from "../fonts/avenir-next/avenir-next-bold.woff";

export default {
	AvenirTTF,
	AvenirWOFF,
	AvenirLightTTF,
	AvenirLightWOFF,
	AvenirHeavyTTF,
	AvenirHeavyWOFF,
	AvenirMediumTTF,
	AvenirMediumWOFF,

	AvenirNextOTF,
	AvenirNextWOFF,
	AvenirNextSemiBoldOTF,
	AvenirNextSemiBoldWOFF,
	AvenirNextBoldOTF,
	AvenirNextBoldWOFF,
}