import styled from 'styled-components';

const Button = styled.button`
	display: inline-flex;
	align-items: center;
	padding: ${({ size }) => size === 'small' ? '9px 23px' : '18px 24px'};
	border-radius: ${({ theme }) => theme.radius.card};
	border: 0;
	font-size: ${({ theme }) => theme.fontSize.body};
	text-decoration: none;
	background: ${({ theme }) => theme.color.brand};
	color: ${({ theme }) => theme.color.white};
	
	&:hover {
		background: ${({ theme }) => theme.color.brandHover};
	}
	
	&:focus {
		&:active {
			outline: 0;
		}
	}
	
	&:active {
		opacity: .9;
	}
	
	&:disabled {
		cursor: default;
		background: ${({ theme }) => theme.color.disabled};
	}
`;

export default Button;