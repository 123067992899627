import React, { Fragment, useState, useEffect } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import SVG from 'react-svg';
import Headroom from 'react-headroom';

import Button from './Button';
import Container from './Container';
// import { BrandButton } from '../Buttons';
// import { Context } from '../Misc';

const Header = () => {

	let {
		menu,
		options: { options }
	} = useStaticQuery(graphql`
		{
		  options: wordpressAcfOptions {
			options {
			  logo {
				sizes {
					large {
						source_url
						alt_text
					}
				}
			  }
			}
		  }
		  menu: wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
			items {
			  url
			  object_slug
			  title
			  type_label
			  target
			  classes
			}
		  }
		}
	`);

	let [menuOpen, setMenuOpen] = useState(false);

	let location;
	if(typeof window !== 'undefined') {
		location = window.location.href;
	}

	useEffect(() => {
		setMenuOpen(false);
	}, [location]);

	return(
		<Fragment>
			<HeaderOuter>
				<Inner>
					<Container
						size={'wide'}
					>
						{ options.logo && options.logo.sizes ? (
							<Link to={'/'}>
								<img
									src={options.logo.sizes.large.source_url}
									alt={options.logo.sizes.large.alt_text}
								/>
							</Link>
						) : null }

						{ menu.items && !!menu.items.length ? (
							<Fragment>
								<Menu>
									{ renderMenu(menu.items) }
								</Menu>

								<Hamburger
									onClick={() => setMenuOpen(!menuOpen)}
								>
									<SVG
										src={'/icons/bars.svg'}
									/>
								</Hamburger>
							</Fragment>
						) : null }
					</Container>
				</Inner>
				<MobileMenu
					open={menuOpen}
				>
					{ renderMenu(menu.items) }
				</MobileMenu>
			</HeaderOuter>

		</Fragment>
	);
}

const renderMenu = (items) => (
	<ul>
		{ items.map(({ url, object_slug, title, type_label, target, classes, wordpress_children }, i) => {
			url = url.includes('#') ? url : object_slug;

			return(
				<li
					className={url === '#' ? 'has-children' : ''}
					key={`headerMenu-${title}`}
				>
					{ i + 1 === items.length ? (

						<Button
							as={Link}
							to={url}
							className={classes}
							size={'small'}
						>{ title }</Button>

					) : (

						<Link
							to={url}
							className={classes}
						>{ title }</Link>

					) }

					{ wordpress_children && !!wordpress_children.length ? (
						<ul className={'sub-menu'}>
							{ wordpress_children.map((child) => (
								<li key={`headerSubMenu-${child.title}`}>
									<Link
										to={child.url === '#' ? child.url : `${object_slug === 'poet-in-action' ? 'case-study' : object_slug}/${child.object_slug}`}
										className={child.classes}
									>{ child.title }</Link>
								</li>
							)) }
						</ul>
					) : null }
				</li>
			);
		}) }
	</ul>
);

const Inner = styled.div`
	position: relative;
	z-index: 2;
	background: white;
`;

const Hamburger = styled.button`
	border: 0;
	background: none;
	
	svg {
		width: 22px;
		fill: ${({ theme }) => theme.color.brand};
	}
	
	&:focus {
		outline: 0;
	}
	
	@media (min-width: 700px) {
		display: none;
	}
`;

const MobileMenu = styled.nav`
	position: absolute;
	top: 100px;
	left: 0;
	width: 100%;
	transform: translateY(${({ open }) => open ? '0' : 'calc(-100% - 100px)'});
	transition: transform .5s ease-in-out;
	background: ${({ theme }) => theme.color.blue};
	z-index: 1;
	
	a {
		display: block;
		padding: 25px 15px;
		text-align: center;
		text-decoration: none;
		color: ${({ theme }) => theme.color.white};
		font-family: ${({ theme }) => theme.fontFamily.sansMedium};
		font-size: ${({ theme }) => theme.fontSize.bodyLg};
	
		&:active:focus {
			outline: 0;
		}
	}

	@media (min-width: 700px) {
		display: none;
	}
`;

const Menu = styled.nav`
	ul {
		display: flex;
		align-items: center;
		
		li {
			+li {
				margin-left: 48px;
			}
			
			&:not(:last-of-type) {
				a {
					text-decoration: none;
					color: ${({ theme }) => theme.color.text};
					font-family: ${({ theme }) => theme.fontFamily.sansMedium};
					
					&:active:focus {
						outline: 0;
					}
				}
			}
		}
	}
	
	@media (max-width: 699px) {
		display: none;
	}
`;

const HeaderOuter = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: ${({ theme }) => theme.color.white};
	z-index: 22;
	
	${Container} {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 100px;
		
		img {
			position: relative;
			top: 4px;
			width: 110px;
		}
	}
`;

export default Header;
