import React, { Fragment } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import styled from 'styled-components';
import SVG from 'react-svg';

import Container from './Container';

const Footer = () => {

	let { options, menu } = useStaticQuery(graphql`
		{
		  options: wordpressAcfOptions {
			options {
			  logo {
				sizes {
				  large {
					source_url
				  }
				}
			  }
			  address
			  telephone
			  registration_number
			  sponsors_title
			  sponsors {
				name
				url
				image {
				  source_url
				  alt_text
				}
			  }
			  social_media {
			    type
				url
			  }
			  affiliations_title
			  affiliations {
				sponsors {
				  name
				  url
				  image {
					source_url
					alt_text
				  }
				}
			  }
			}
		  }
		  menu: wordpressWpApiMenusMenusItems(name: {eq: "Footer Menu"}) {
			items {
			  url
			  title
			  type_label
			  target
			  classes
			  object_slug
			}
		  }
		}
	`);

	let {
		logo,
		address,
		telephone,
		registration_number,
		sponsors_title,
		sponsors,
		social_media,
		affiliations_title,
		affiliations: {
			sponsors: affiliates
		},
	} = options.options;

	// let {
	// 	logo_light,
	// 	address,
	// 	telephone,
	// 	telephone_alt,
	// 	social_media
	// } = options.options;

	return (
		<Outer className={"footer"}>
			<Upper>
				<Container
					size={'wide'}
				>
					<FooterLeft>
						{ logo && logo.sizes ? (
							<Link to={'/'}>
								<img
									src={logo.sizes.large.source_url}
									alt={logo.sizes.large.alt_text}
								/>
							</Link>
						) : null }

						{ social_media && !!social_media.length ? (
							<SocialLinks>
								{ social_media.map(({ type, url }) => (
									<li>
										<a
											href={url}
											target={'_blank'}
											title={type}
										>
											<SVG
												src={`/icons/${type}.svg`}
											/>
										</a>
									</li>
								)) }
							</SocialLinks>
						) : null }

						{ telephone ? (
							<a href={`tel:${telephone}`}>{ telephone }</a>
						) : null }

						{ registration_number ? (
							<span>{ registration_number }</span>
						) : null }
					</FooterLeft>

					<FooterRight>
						{ address ? (
							<address dangerouslySetInnerHTML={{ __html: address }} />
						) : null }

						{ menu.items && !!menu.items.length ? (
							<Menu>
								<ul>
									{ menu.items.map(({ url, object_slug, title, type_label, target, classes, wordpress_children }) => {

										url = url.includes('#') || url.charAt(0) === '/' ? url : object_slug;

										return(
											<li key={`footerMenu-${title}`}>
												<Link
													to={url}
													className={classes}
												>{ title }</Link>
											</li>
										);
									}) }
								</ul>
							</Menu>
						) : null }

						{ sponsors && !!sponsors.length ? (
							<Sponsors>
								{ sponsors_title ? (
									<h6>{ sponsors_title }</h6>
								) : null }

								<Logos>
									{ sponsors.map(({ name, url, image }) => {
										if(!image) return null;

										return(
											<Fragment key={`footer-logo-${name}`}>
												{ url ? (
													<a href={url} target={'_blank'}>
														<img src={image.source_url} alt={image.alt_text}/>
													</a>
												) : (
													<img src={image.source_url} alt={image.alt_text}/>
												) }
											</Fragment>
										);
									}) }
								</Logos>
							</Sponsors>
						) : null }

						{ affiliates && !!affiliates.length ? (
							<Affiliates>
								{ affiliations_title ? (
									<h6>{ affiliations_title }</h6>
								) : null }

								<Logos>
									{ affiliates.map(({ name, url, image }) => {
										if(!image) return null;

										return(
											<div
												key={`footer-logo-${name}`}
												className={'footer-logo'}
											>
												{ url ? (
													<a href={url} target={'_blank'}>
														<img src={image.source_url} alt={image.alt_text}/>
													</a>
												) : (
													<img src={image.source_url} alt={image.alt_text}/>
												) }
											</div>
										);
									}) }
								</Logos>
							</Affiliates>
						) : null }
					</FooterRight>
				</Container>
			</Upper>

			<Lower>
				<Container
					size={'wide'}
				>
					<Link to={'/privacy-policy'}>Privacy Policy</Link>
					<span>&copy; WR Investigations { new Date().getFullYear() }</span>
				</Container>
			</Lower>
		</Outer>
	);
};

const SocialLinks = styled.ul`
	display: flex;
	padding: 0;
	margin: 5px 0 20px!important;
	list-style-type: none;
	
	li {
		+li {
			margin-left: 12px;
		}
	}
	
	svg {
		width: 16px;
		fill: ${({ theme }) => theme.color.blue};
		transition: .3s;
	}
	
	a {
		&:hover {
			svg {
				fill: ${({ theme }) => theme.color.blueHover};
			}
		}
	}
`;

const Menu = styled.nav``;

const Logos = styled.div`
	display: flex;
	
	.footer-logo {
		+* {
			margin-left: 16px;
		}
	}
	
	img {
		width: auto;
		max-width: 252px;
		max-height: 140px;
	}
	
	@media (max-width: 991px) {
		align-items: center;
	}
`;

const Affiliates = styled.div``;

const Sponsors = styled.div`
	img {
		height: 60px;
	}
`;

const FooterLeft = styled.div`
	img {
		width: 82px;
		margin-bottom: 18px;
	}
	
	span {
		display: block;
	}
	
	>* {
		+* {
			margin-top: 15px;
		}
	}
`;

const FooterRight = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex: 1;
	padding-left: 12%;
	
	nav,
	address {
		line-height: 192%;
		font-family: ${({ theme }) => theme.fontFamily.sansMedium};
	}
	
	>* {
		+* {
			margin-left: 50px;
		}
	}

`;

const Upper = styled.div`
	padding: 35px 0;
	background: ${({ theme }) => theme.color.white};
	
	${Container} {
		display: flex;
		justify-content: space-between;
	}
	
	a, h6 {
		color: ${({ theme }) => theme.color.text};
	}
`;

const Lower = styled.div`
	padding: 20px 0;
	background: ${({ theme }) => theme.color.blue};
	color: ${({ theme }) => theme.color.white};
	font-family: ${({ theme }) => theme.fontFamily.sans};
	font-size: ${({ theme }) => theme.fontSize.bodySm};
	
	${Container} {
		display: flex;
		justify-content: flex-end;
		
		>* {
			+* {
				padding-left: 11px;
				margin-left: 11px;
				border-left: 1px solid white;
			}
		}
	}
	
	a {
		color: ${({ theme }) => theme.color.white};
	}
`;

const Outer = styled.footer`
	font-family: ${({ theme }) => theme.fontFamily.sansMedium};
	
	a,h6 {
		display: table;
		text-decoration: none;
		font-family: ${({ theme }) => theme.fontFamily.sansMedium};
		white-space: nowrap;
	}
	
	${Lower} {
		a {
			font-family: ${({ theme }) => theme.fontFamily.sans};
		}
	}
	
	h6 {
		margin-bottom: 12px;
		white-space: nowrap;
	}
	
	@media (max-width: 1289px) {
		${FooterRight} {
			padding-left: 8%;
		}
		
		${Logos} {
			flex-direction: column;
			
			.footer-logo {
				+* {
					margin: 12px 0 0;
				}
			}
		}
	}
	
	@media (max-width: 991px) {
		${Upper} {
			${Container} {
				flex-direction: column;
			}
		}
		
		${FooterRight} {
			order: -1;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			padding: 0;
			text-align: center;
			
			>* {
				width: 100%;
				
				+* {
					margin: 35px 0 0 0;
				}
			}
		}
		
		${Sponsors},
		${Affiliates} {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		
		${Logos} {
			flex-direction: row;
			justify-content: center;
			
			.footer-logo {
				+* {
					margin: 0 0 0 32px;
				}
			}
		}
		
		${Menu} {
			a {
				margin-left: auto;
				margin-right: auto;
			}
		}
		
		${FooterLeft} {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 50px;
		}
		
		h6 {
			text-align: center;
		}
	}
	
	@media (max-width: 479px) {
		${Logos} {
			flex-direction: column;
			align-items: center;
			
			.footer-logo {
				+* {
					margin: 12px auto 0;
				}
			}
		}
	}
`;

export default Footer;
