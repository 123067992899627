import React, { Fragment, useRef, useEffect } from "react";
import styled, { ThemeProvider } from "styled-components";
import { useStaticQuery, graphql } from 'gatsby';
import { Reset } from "styled-reset";
import ReactModal from 'react-modal';

import theme from '../styles/theme';
import GlobalStyles from '../styles/global';
import Header from '../components/Header';
import Footer from '../components/Footer';

ReactModal.setAppElement('#___gatsby');

const Layout = ({ children }) => {

	const handleKeyDown = e => {
		if(e.keyCode === 9) {
			document.body.classList.add('user-is-tabbing');
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown);

		return () => {
			window.removeEventListener('keydown', handleKeyDown);
		}
	}, []);

	return(
		<ThemeProvider theme={theme}>
			<Fragment>
				<Reset/>
				<GlobalStyles/>

				<Header/>
				{children}
				<Footer/>

				<HiddenForms />
			</Fragment>
		</ThemeProvider>
	);
}

const HiddenForms = () => (
	<Fragment>
		<form
			name={'contact'}
			data-netlify="true"
			netlify-honeypot="honeypot"
			style={{
				display: 'none'
			}}
		>
			<input type="email" name={'email'} />
			<input type="checkbox" name={'message'} />
		</form>
	</Fragment>
);

export default Layout;